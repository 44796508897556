// Action Types
export const FETCH_NEARBY_PRODUCTS_REQUEST = 'FETCH_NEARBY_PRODUCTS_REQUEST';
export const FETCH_NEARBY_PRODUCTS_SUCCESS = 'FETCH_NEARBY_PRODUCTS_SUCCESS';
export const FETCH_NEARBY_PRODUCTS_FAILURE = 'FETCH_NEARBY_PRODUCTS_FAILURE';

// Action Creators
export const fetchNearbyProductsRequest = () => ({
    type: FETCH_NEARBY_PRODUCTS_REQUEST,
});

export const fetchNearbyProductsSuccess = (products) => ({
    type: FETCH_NEARBY_PRODUCTS_SUCCESS,
    payload: products,
});

export const fetchNearbyProductsFailure = (error) => ({
    type: FETCH_NEARBY_PRODUCTS_FAILURE,
    payload: error,
});
