import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchRestaurantsRequest,
  fetchRestaurantsSuccess,
  fetchRestaurantsFailure,
} from '../redux/actions/restaurantActions';
import {  clearCart } from '../redux/actions/cartActions';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { haversineDistance } from '../helpers/geoUtils';
import '../style/showRestaurants.css'; // Make sure to create this CSS file for styles

function ShowRestaurants({ userId,userLat, userLon }) {

  const dispatch = useDispatch();
  const { loading, restaurants, error } = useSelector((state) => state.restaurants);
  const [searchTerm, setSearchTerm] = useState('');
  // const [hasCheckedLocation, setHasCheckedLocation] = useState(false); // Flag to track if location check has occurred

  // Use userLat and userLon directly
  const userLaton = userLat;
  const userLonon = userLon;

  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(() => {
    
    const fetchRestaurants = async () => {
      if (userLaton && userLonon) {
        dispatch(fetchRestaurantsRequest());
        try {
          const querySnapshot = await getDocs(collection(db, 'Restorants'));
          const fetchedRestaurants = [];

          querySnapshot.forEach((doc) => {
            const {
              date,
              gpsX,
              gpsY,
              h_telegram,
              h_about,
              h_address,
              h_email,
              h_eventPost,
              h_id,
              h_is_Active,
              h_is_Official,
              h_logo,
              h_name,
              h_phone,
              h_rate,
              h_services,
              host_name,
              nearToYou,
              telegramId,
              workPost,
            } = doc.data();

            const distance = haversineDistance(userLaton, userLonon, gpsX, gpsY);

            if (distance <= 30) { // Assuming a 30 km radius
              fetchedRestaurants.push({
                id: doc.id,
                date,
                h_telegram,
                gpsX,
                gpsY,
                h_about,
                h_address,
                h_email,
                h_eventPost,
                h_id,
                h_is_Active,
                h_is_Official,
                h_logo,
                h_name,
                h_phone,
                h_rate,
                h_services,
                host_name,
                nearToYou,
                telegramId,
                workPost,
                distance,
              });
            }
          });

          dispatch(fetchRestaurantsSuccess(fetchedRestaurants));
        } catch (error) {
          dispatch(fetchRestaurantsFailure(error.message));
        }
      }
    };

    fetchRestaurants();
  }, [dispatch, userLaton, userLonon]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Filter restaurants based on the search term
  const filteredRestaurants = restaurants.filter((restaurant) =>
    restaurant.h_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle restaurant card click
  const handleRestaurantClick = (restaurantId, restaurantdata) => {
    // Navigate to the FetchProductsPage with the selected restaurant's ID
    dispatch(clearCart());
    navigate(`/products/${restaurantId}/${restaurantdata.distance}/${restaurantdata.h_name}`, { state: { restaurantdata } });
  };
  // Function to display stars based on rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Full stars
    const halfStar = rating % 1 !== 0; // Check if there's a half star
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining stars

    return (
      <>
        {Array(fullStars).fill('⭐').map((star, index) => <span key={index}>{star}</span>)}
        {halfStar && <span>⭐</span>} {/* Optional half star */}
        {Array(emptyStars).fill('☆').map((star, index) => <span key={index}>{star}</span>)} {/* Empty stars */}
      </>
    );
  }

  return (
    <div className="app-container-rest">
      {userLaton && userLonon ? (
        <>
          <input
            type="text"
            className="search-input-rest"
            placeholder="Search restaurants..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="restaurant-list">
            {filteredRestaurants.map((restaurant) => (
              <div
                className="restaurant-card"
                key={restaurant.id}
                onClick={() => handleRestaurantClick(restaurant.id, restaurant)} // Corrected the key here to restaurant.id
              >
                <h2>{restaurant.h_name}</h2>
                <p> Location :{restaurant.h_address}</p>
                <p>
                  {renderStars(restaurant.h_rate)} ({restaurant.h_rate} Rating)
                </p>
                <p>Distance: {restaurant.distance.toFixed(3)} km away</p>
              </div>
            ))}
          </div>
        </>
      ) : (
        // If userLaton and userLonon are not available, show a message
        <div className="centered-message">
        <p>Please wait....</p>
      </div>

      )}
    </div>
  );
}

export default ShowRestaurants;
