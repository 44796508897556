import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import restaurantReducer from './reducers/restaurantReducer';
import productReducer from './reducers/productReducer';
import cartReducer from    "./reducers/cartReducer";
import productNearReducer from "./reducers/productNearReducer";
import userReducer from './reducers/orderReducer'; // Assuming you have a userReducer for managing user data
import helperReducer  from "./reducers/helperReducer";
// Combine reducers
const rootReducer = combineReducers({
  restaurants: restaurantReducer,
  products: productReducer,
  cart: cartReducer, // Include the cart reducer here
  nearbyProducts: productNearReducer, // Add to combined reducers
  orders:userReducer,
  helpers:helperReducer
  
});

// Create store without middleware
const store = createStore(
  rootReducer,
  composeWithDevTools() // No middleware applied
);

export default store;
