import {
    FETCH_NEARBY_PRODUCTS_REQUEST,
    FETCH_NEARBY_PRODUCTS_SUCCESS,
    FETCH_NEARBY_PRODUCTS_FAILURE,
} from '../actions/productNearActions';

const initialState = {
    loading: false,
    products: [],
    error: null,
};

const productNearReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NEARBY_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case FETCH_NEARBY_PRODUCTS_SUCCESS:
            return { loading: false, products: action.payload, error: null };
        case FETCH_NEARBY_PRODUCTS_FAILURE:
            return { loading: false, products: [], error: action.payload };
        default:
            return state;
    }
};

export default productNearReducer;
