import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../firebaseConfig'; // Import your Firebase configuration
import { collection, getDocs } from 'firebase/firestore';
import { calculateDistance } from '../helpers/geoUtils'; // Import the distance calculation function
import { fetchHelpersRequest, fetchHelpersSuccess, fetchHelpersFailure } from '../redux/actions/helperActions';
import '../style/HelpByLocation.css'; // Import your CSS for styling

const HelpByLocation = ({ lat, lon }) => {
  const dispatch = useDispatch();

  // Access the helpers state from the Redux store
  const { loading, helpers, error } = useSelector((state) => state.helpers);

  // Function to fetch helpers
  const fetchHelpers = async () => {
    dispatch(fetchHelpersRequest()); // Dispatch the request action
    try {
      const helpersCollection = collection(db, 'Help');
      const querySnapshot = await getDocs(helpersCollection);

      const fetchedHelpers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filter helpers based on distance
      const nearbyHelpers = fetchedHelpers.filter((helper) => {
        const distance = calculateDistance(lat, lon, helper.location.lat, helper.location.lon);
        return distance <= 30; // 30 kilometers radius
      });

      dispatch(fetchHelpersSuccess(nearbyHelpers)); // Dispatch success action with helpers
    } catch (err) {
      dispatch(fetchHelpersFailure(err.message)); // Dispatch failure action with error
    }
  };

  useEffect(() => {
    if (lat && lon) {
      fetchHelpers();
    }
  }, [lat, lon, dispatch]); // Add dispatch to the dependency array

  // Function to copy phone number to clipboard
  const copyToClipboard = (phone) => {
    navigator.clipboard.writeText(phone).then(() => {
      alert('Phone number copied to clipboard!');
    }).catch((err) => {
     
    });
  };

  return (
    <div className="help-container">
     
      {loading && <p className="help-loading">Loading helpers...</p>}
      {error && <p className="help-error">Error fetching helpers: {error}</p>}

      {helpers.length > 0 ? (
        <ul className="helpers-list">
          {helpers.map((helper) => (
            <li key={helper.id} className="helper-card">
              <h4 className="helper-name">{helper.name}</h4>
              <p><strong>Address:</strong> {helper.address}</p>
              <p>
                <strong>Phone:</strong>{' '}
                <a href={`tel:${helper.phone}`} className="helper-phone">{helper.phone}</a>{' '}
                <button onClick={() => copyToClipboard(helper.phone)} className="copy-button">
                  Copy
                </button>
              </p>
              <p>
                <strong>Distance:</strong>{' '}
                {calculateDistance(lat, lon, helper.location.lat, helper.location.lon).toFixed(1)} KM
              </p>
            </li>
          ))}
        </ul>
      ) : (
        !loading && <p className="no-helpers">No helpers found within 30 km.</p>
      )}
    </div>
  );
};

export default HelpByLocation;
