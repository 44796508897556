// redux/reducers/restaurantReducer.js
import {
    FETCH_RESTAURANTS_REQUEST,
    FETCH_RESTAURANTS_SUCCESS,
    FETCH_RESTAURANTS_FAILURE,
    FETCH_SINGLE_RESTAURANT_REQUEST,
    FETCH_SINGLE_RESTAURANT_SUCCESS,
    FETCH_SINGLE_RESTAURANT_FAILURE,
  } from '../actions/restaurantActions';
  
  const initialState = {
    loading: false,
    restaurants: [],
    restaurant: null, // Change to null for a single restaurant
    error: null,
  };
  
  const restaurantReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_RESTAURANTS_REQUEST:
        return { ...state, loading: true };
      case FETCH_RESTAURANTS_SUCCESS:
        return { loading: false, restaurants: action.payload, error: null };
      case FETCH_RESTAURANTS_FAILURE:
        return { loading: false, restaurants: [], error: action.payload };
      // this is fetch single restaurants 

      // Handle single restaurant actions
      case FETCH_SINGLE_RESTAURANT_REQUEST:
          return { ...state, loading: true, restaurant: null }; // Reset restaurant to null when fetching
      case FETCH_SINGLE_RESTAURANT_SUCCESS:
          return { ...state, loading: false, restaurant: action.payload, error: null };
      case FETCH_SINGLE_RESTAURANT_FAILURE:
          return { ...state, loading: false, restaurant: null, error: action.payload };

      default:
        return state;
    }
  };
  
  export default restaurantReducer;
  