import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BottomNavbar.css'; // Ensure this file includes your styles

function BottomNavbar() {
  const location = useLocation();

  return (
    <div className="bottom-navbar">
      <Link to="/" className={`button-link ${location.pathname === '/' ? 'active' : ''}`}>
        Restaurants
      </Link>
      <Link to="/search" className={`button-link ${location.pathname === '/search' ? 'active' : ''}`}>
        Search 
      </Link>
      <Link to="/tabbed" className={`button-link ${location.pathname === '/orders' ? 'active' : ''}`}>
        Orders
      </Link>
    </div>
  );
}

export default BottomNavbar;
