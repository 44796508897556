// redux/actions/restaurantActions.js


// Action types
export const FETCH_RESTAURANTS_REQUEST = 'FETCH_RESTAURANTS_REQUEST';
export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';
export const FETCH_RESTAURANTS_FAILURE = 'FETCH_RESTAURANTS_FAILURE';

// New action types for fetching a single restaurant
export const FETCH_SINGLE_RESTAURANT_REQUEST = 'FETCH_SINGLE_RESTAURANT_REQUEST';
export const FETCH_SINGLE_RESTAURANT_SUCCESS = 'FETCH_SINGLE_RESTAURANT_SUCCESS';
export const FETCH_SINGLE_RESTAURANT_FAILURE = 'FETCH_SINGLE_RESTAURANT_FAILURE';

// Action creators
const fetchRestaurantsRequest = () => ({ type: FETCH_RESTAURANTS_REQUEST });
const fetchRestaurantsSuccess = (restaurants) => ({ type: FETCH_RESTAURANTS_SUCCESS, payload: restaurants });
const fetchRestaurantsFailure = (error) => ({ type: FETCH_RESTAURANTS_FAILURE, payload: error });
// New action creators for fetching a single restaurant

const fetchSingleRestaurantRequest = () => ({ type: FETCH_SINGLE_RESTAURANT_REQUEST });
const fetchSingleRestaurantSuccess = (restaurant) => ({ type: FETCH_SINGLE_RESTAURANT_SUCCESS, payload: restaurant });
const fetchSingleRestaurantFailure = (error) => ({ type: FETCH_SINGLE_RESTAURANT_FAILURE, payload: error });


// Exporting action creators
export {
  fetchRestaurantsRequest,
  fetchRestaurantsSuccess,
  fetchRestaurantsFailure,

  fetchSingleRestaurantRequest,
  fetchSingleRestaurantSuccess,
  fetchSingleRestaurantFailure,
};
