import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "../style/Profile.css";
import { FaCheckCircle } from 'react-icons/fa'; // Example using a Font Awesome icon

const Profile = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [profileData, setProfileData] = useState(null);
  const [name, setName] = useState('');
  const [university, setUniversity] = useState('');
  const [dormNumber, setdormNumber] = useState(''); // New state for dormNumber
  const [formActive, setFormActive] = useState(false); // State to track form interaction


  const universityCoordinates = {
    'Mekelle University': { lat: 13.49, lon: 39.47 },
    'Hawassa University': { lat: 7.06, lon: 38.48 },
    'Gondar University': { lat: 12.6, lon: 37.46 },
    'Arba Minch University': { lat: 6.03, lon: 37.55 },
    'Adama Science and Technology University': { lat: 8.55, lon: 39.27 },
    'Debre Berhan University': { lat: 9.67, lon: 39.52 },
    'Jimma University': { lat: 7.67, lon: 36.83 },
    'Addis Ababa University': { lat: 9.03, lon: 38.74 },
    'Bahir Dar University': { lat: 11.60, lon: 37.39 },
    'Dilla University': { lat: 6.42, lon: 38.31 },
    'Dire Dawa University': { lat: 9.60, lon: 41.85 },
    'Debre Markos University': { lat: 10.37, lon: 37.73 },
    'Kombolcha University': { lat: 11.08, lon: 39.78 },
    'Woldia University': { lat: 11.52, lon: 39.60 },
    'Wollo University': { lat: 11.75, lon: 39.60 },
    'Haramaya University': { lat: 9.30, lon: 42.05 },
    'Gambela University': { lat: 8.25, lon: 34.58 },
    'Mettu University': { lat: 8.38, lon: 34.29 },
    'Shashemene University': { lat: 7.20, lon: 38.60 },
    'Bule Hora University': { lat: 7.08, lon: 39.61 },
  };

  const loadProfileData = () => {
    const storedProfile = localStorage.getItem('userProfile');
    if (storedProfile) {
      const parsedProfile = JSON.parse(storedProfile);
      setProfileData(parsedProfile);
      setName(parsedProfile.name);
      setUniversity(parsedProfile.university);
      setdormNumber(parsedProfile.dormNumber || ''); // Load dormNumber if exists
    } else {
      // Redirect to the registration page if no profile data is found
      navigate('/profile'); // Change this path to your actual registration page path
    }
  };

  const updateProfile = () => {
    const updatedProfile = { ...profileData, name, university, dormNumber }; // Include dormNumber in the update
    localStorage.setItem('userProfile', JSON.stringify(updatedProfile));
    setProfileData(updatedProfile);
    navigate('/')
    window.location.reload(); // Reload the page to reset the application state
  };
  

  const updateLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setProfileData((prevData) => ({
            ...prevData,
            lat: latitude,
            lon: longitude,
          }));
          
          // Optionally update local storage with new coordinates
          const updatedProfile = { ...profileData, lat: latitude, lon: longitude };
          localStorage.setItem('userProfile', JSON.stringify(updatedProfile));
        },
        (error) => {
         
          alert("Unable to retrieve your location. Please allow location access in your browser settings.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const clearProfile = () => {
    localStorage.clear(); // Clear all local storage data
    window.location.reload(); // Reload the page to reset the application state
    navigate(`/`);
  
  };

  useEffect(() => {
    loadProfileData();
  }, []);


const capitalizeFirstLetter = (name) => {
  if (!name) return '';
  return name.charAt(0).toUpperCase() + name.slice(1);
};

return (
  <div className="profile-container" style={{ maxWidth: '500px', margin: '0 auto' }}>
    {profileData ? (
      <div className="profile-card">
         <div className="profile-details">
         <h3>
            {capitalizeFirstLetter(profileData.name)}
            
            {/* Check if userId is valid */}
            {Number(profileData.userId) ? (
              
              // Display official icon if userId is found
              <FaCheckCircle style={{ marginLeft: '5px', color: 'green' }} />
            ) : (
              // Display "Unofficial Account" text if userId is not found
              <span style={{ marginLeft: '5px', color: 'red', fontStyle: 'italic' }}>Unofficial Account</span>
            )}
          </h3>
          <p><strong>Phone Number:</strong> {profileData.phone}</p>
          {/* <p><strong>Location:</strong> Lat: {profileData.lat || 'N/A'}, Lon: {profileData.lon || 'N/A'}</p> */}
          <p><strong>University:</strong> {profileData.university || 'N/A'}</p>
          <p><strong>Dorm Number:</strong> {profileData.dormNumber || 'N/A'}</p>

          {/* Form to update the profile */}
          <div className="profile-edit">
            <h4>Update Profile Settings</h4>
            <label>
              Update Name:
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setFormActive(true); // Set form active on input change
                }}
                placeholder="Enter new name"
              />
            </label>
            <br />
            <label>
              Update University:
              <select
                value={university}
                onChange={(e) => {
                  setUniversity(e.target.value);
                  setFormActive(true); // Set form active on dropdown change
                  const coordinates = universityCoordinates[e.target.value];
                  if (coordinates) {
                    // Update latitude and longitude based on selected university
                    setProfileData((prevData) => ({
                      ...prevData,
                      lat: coordinates.lat,
                      lon: coordinates.lon,
                    }));
                  }
                }}
              >
                <option value="">Select University</option>
                {Object.keys(universityCoordinates).map((univ) => (
                  <option key={univ} value={univ}>{univ}</option>
                ))}
              </select>
            </label>
            <br />
            <label>
              Update Dorm Number:
              <input
                type="text"
                value={dormNumber}
                onChange={(e) => {
                  setdormNumber(e.target.value);
                  setFormActive(true); // Set form active on input change
                }}
                placeholder="Enter dorm number"
              />
            </label>
            <br />
            
            {/* Conditionally render buttons based on form interaction */}
            {formActive && (
              <div className="button-row">
                <button onClick={updateProfile} className="update-button">Update Profile</button>
                <button onClick={updateLocation} className="update-location-button">Update Location</button>
                <button onClick={clearProfile} className="clear-profile-button">Delete Profile</button>
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <p className="no-profile">No profile data found. Please ensure you are logged in or have a saved profile.</p>
    )}
  </div>
);
};

export default Profile;
