// redux/reducers/cartReducer.js
const initialState = {
    items: [],
  };
  
  const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_TO_CART':
        return {
          ...state,
          items: [...state.items, action.payload],
        };
      case 'INCREMENT_ITEM':
        return {
          ...state,
          items: state.items.map(item =>
            item.id === action.payload ? { ...item, quantity: item.quantity + 1 } : item
          ),
        };
    case 'REMOVE_FROM_CART':
            return {
              ...state,
              items: state.items.filter(item => item.id !== action.payload),
            };
      case 'DECREMENT_ITEM':
        return {
          ...state,
          items: state.items.map(item =>
            item.id === action.payload && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
          ),
        };
      case 'CLEAR_CART': // New action type for clearing the cart
        return {
          ...state,
          items: [], // Reset items to an empty array
        };
      default:
        return state;
    }
  };
  
  export default cartReducer;
  