

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ShowRestaurants from './Pages/ShowRestaurants';
import FetchProductsPage from './Pages/ShowRestaurantsProducts';
import ProductsNearSearchPage from './Pages/ProductsNearSearchPage';
import BottomNavbar from './BottomNavbar';
import ShowMyOrders from './Pages/ShowMyOrders';
import ProfileForm from './Pages/ProfileForm';
import TabbedPage from "./Pages/TabbedPage"
import "./App.css"

const tele = window.Telegram.WebApp;

function App() {
    const [isProfileComplete, setIsProfileComplete] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [userLat, setUserLat] = useState(null);
    const [userLon, setUserLon] = useState(null);
    
    const [userId, setUserId] = useState(null);
//  just
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    university: '',
    dormNumber: '',
    lat: null,
    lon: null,
  });

  useEffect(() => {
    const retrievedProfile = localStorage.getItem('userProfile');
   
    
    if (retrievedProfile) {
      const userProfile = JSON.parse(retrievedProfile);
    
      setUserLat(userProfile.lat);
      setUserLon(userProfile.lon);
      
    }
  }, []); // Run once when the component mounts

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tele = window.Telegram.WebApp;
      tele.ready(); // Initialize the Telegram WebApp
    }
  }, []);
  

  // Check if user profile exists in localStorage
  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');
    if (userProfile) {
      setIsProfileComplete(true);
    }
  }, []);

  // Fetch user's location (lat/lon)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          
          const newLat = position.coords.latitude;
          const newLon = position.coords.longitude;
          setUserLat(newLat);
          setUserLon(newLon);
           // Update formData with new coordinates
           setFormData(prevData => ({
            ...prevData,
            lat: newLat,
            lon: newLon,
          }));
          const retrievedProfile = localStorage.getItem('userProfile');
           
           const userProfile = JSON.parse(retrievedProfile);
           if (retrievedProfile){
            const userData = {
              Location: [`${newLat}° N`, `${newLon}° E`],
              timestamp: new Date().toISOString(), // or your desired timestamp format
              name: userProfile.name,
              phone: userProfile.phone,
              role: 'user',
              university: userProfile.university,
              dormNumber: userProfile.dormNumber,
              lat:parseFloat(newLat),
              lon:parseFloat(newLon)
            };
          
            localStorage.setItem('userProfile', JSON.stringify(userData));
           }
          // Save profile data to local storage
         
        },
        (error) => {
          
          const retrievedProfile = localStorage.getItem('userProfile');
          if (retrievedProfile) {
            
            const userProfile = JSON.parse(retrievedProfile);
            setUserLat(userProfile.lat);
            setUserLon(userProfile.lon);
          }
        }
      );
    } else {
      // Attempt to use the local storage coordinates if geolocation is not supported
      const retrievedProfile = localStorage.getItem('userProfile');
      if (retrievedProfile) {
        const userProfile = JSON.parse(retrievedProfile);
       
        setUserLat(userProfile.lat);
        setUserLon(userProfile.lon);
      }
    }
  }, []);

  
  useEffect(() => {
    // Ensure that the Telegram WebApp object is available
    const tele = window.Telegram.WebApp;

    const user = tele.initDataUnsafe?.user;
    if (user) {
      setUserId(user.id); // Set the userId in the state
    } else {
      // Handle the case where no user data is available (optional)
      console.log("No user data available");
    }
  }, []);


  // Monitor internet connection
  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  const handleProfileSave = () => {
    setIsProfileComplete(true);
  };
  
 
  return (
    <Router>
      <div>
        {!isOnline && (
          <div className="internet-status">
            <p style={{ color: 'green', textAlign: 'center' }}>
              Internet is turned off. Please check your connection.
            </p>
          </div>
        )}

        {!isProfileComplete ? (
          <ProfileForm  onProfileSave={handleProfileSave} />
        ) : (
          <>
            <Routes>
              <Route
                path="/"
                element={<ShowRestaurants userId={userId} userLat={userLat} userLon={userLon} />}
              />
              <Route path="/products/:restaurantId/:distance/:from" element={<FetchProductsPage />} />
              <Route
                path="/orders"
                element={<ShowMyOrders/>}
              />
              <Route
                path="/search"
                element={<ProductsNearSearchPage lat={userLat} lon={userLon}  userId={userId} />}
              />
              <Route
                path="/tabbed"
                element={<TabbedPage lat={userLat} lon={userLon} />} // Add the TabbedPage route
              />
              {/* <ProfileForm  onProfileSave={handleProfileSave} /> */}
              <Route
                path="/profile"
                element={<ProfileForm nProfileSave={handleProfileSave} />} // Add the TabbedPage route
              />
               
            </Routes>
            <BottomNavbar />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;

