import {
    FETCH_HELPERS_REQUEST,
    FETCH_HELPERS_SUCCESS,
    FETCH_HELPERS_FAILURE,
  } from '../actions/helperActions';
  
  const initialState = {
    loading: false,
    helpers: [],
    error: null,
  };
  
  const helperReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_HELPERS_REQUEST:
        return { ...state, loading: true };
      case FETCH_HELPERS_SUCCESS:
        return { loading: false, helpers: action.payload, error: null };
      case FETCH_HELPERS_FAILURE:
        return { loading: false, helpers: [], error: action.payload };
      default:
        return state;
    }
  };
  
  export default helperReducer;
  