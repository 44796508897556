// Define and export constants for action types
export const FETCH_HELPERS_REQUEST = 'FETCH_HELPERS_REQUEST';
export const FETCH_HELPERS_SUCCESS = 'FETCH_HELPERS_SUCCESS';
export const FETCH_HELPERS_FAILURE = 'FETCH_HELPERS_FAILURE';

// Action creators
export const fetchHelpersRequest = () => ({
  type: FETCH_HELPERS_REQUEST,
});

export const fetchHelpersSuccess = (helpers) => ({
  type: FETCH_HELPERS_SUCCESS,
  payload: helpers,
});

export const fetchHelpersFailure = (error) => ({
  type: FETCH_HELPERS_FAILURE,
  payload: error,
});
