import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchNearbyProductsRequest,
    fetchNearbyProductsSuccess,
    fetchNearbyProductsFailure,
} from '../redux/actions/productNearActions';
import {
    fetchSingleRestaurantRequest,
    fetchSingleRestaurantSuccess,
    fetchSingleRestaurantFailure,
} from '../redux/actions/restaurantActions';
import { addToCart, clearCart, incrementItem, decrementItem, removeFromCart } from '../redux/actions/cartActions';
import { getDocs, collection, query, limit, orderBy, doc, getDoc, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import '../style/ProductSearchPage.css';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'; // Import axios


function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
}

function NearbyProductsPage({ lat, lon,  }) {

    const [userId, setUserId] = useState(null);

    useEffect(() => {
      // Retrieve the stored profile from localStorage
      const storedProfile = localStorage.getItem('userProfile');
  
      if (storedProfile) {
        const profileData = JSON.parse(storedProfile);
        setUserId(profileData.userId); // Set userId from stored profile
      }
    }, []);
  

   
  
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.nearbyProducts);

    const [restaurantid, setRestaurantId] = useState("");
    const [restaurantData, setRestaurantData] = useState(null); // State to store fetched restaurant data
    const cartItems = useSelector(state => state.cart.items) || [];
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const [pageSize] = useState(100);
    const [uuid, setUuid] = useState(''); // State to store the UUID
    const [isLoading, setIsLoading] = useState(false);
   
    
    const navigate = useNavigate(); // Initialize the navigate function

   // Method to generate a shortened UUID and return it
   const generateUUID = () => {
        const newUUID = uuidv4(); // Generate a new UUID
        const shortenedUUID = newUUID.replace(/-/g, '').substring(0, 7); // Remove hyphens and limit to 7 characters
        setUuid(shortenedUUID); // Update state with the new shortened UUID
        
        return shortenedUUID; // Return the shortened UUID
    };

    useEffect(() => {
       
        setShowCart(cartItems.length <0);
      }, [cartItems]); // Adding cartItems as a dependency

    const fetchProducts = useCallback(async () => {
        dispatch(fetchNearbyProductsRequest());
        try {
            const q = query(
                collection(db, 'Products'),
                orderBy('p_date'),
                limit(pageSize)
            );
            const querySnapshot = await getDocs(q);
            const fetchedProducts = [];

            querySnapshot.forEach(doc => {
                const productData = { id: doc.id, ...doc.data() };
                const distance = calculateDistance(lat, lon, productData.gpsX, productData.gpsY);
                if (distance <= 30) {
                    fetchedProducts.push(productData);
                }
            });

           
            const searchedProducts = fetchedProducts.filter(product =>
                product.p_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.fromHotel.toLowerCase().includes(searchTerm.toLowerCase())
            );

            dispatch(fetchNearbyProductsSuccess(searchedProducts));
            setFilteredProducts(searchedProducts);
        } catch (error) {
            dispatch(fetchNearbyProductsFailure(error.message));
        }
    }, [dispatch, lat, lon, searchTerm, pageSize]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const handleAddToCart = (product) => {
        setRestaurantId(product.h_id); // Set restaurant ID based on product h_id
        dispatch(clearCart());
        dispatch(addToCart({ ...product, quantity: 1 }));
    };

    const handleIncrement = (productId) => {
        dispatch(incrementItem(productId));
    };

    const handleDecrement = (productId) => {
        const cartItem = cartItems.find(item => item.id === productId);
        if (cartItem) {
            if (cartItem.quantity === 1) {
                dispatch(removeFromCart(productId));
            } else {
                dispatch(decrementItem(productId));
            }
        }
    };

    const closeCart = () => setShowCart(false);

    useEffect(() => {
        if (restaurantid) { // Check if restaurantid is not empty
            const fetchSingleRestaurant = async (restaurantid) => {
                dispatch(fetchSingleRestaurantRequest());
                try {
                    const restaurantRef = doc(db, 'Restorants', restaurantid);
                    const restaurantDoc = await getDoc(restaurantRef);

                    if (restaurantDoc.exists()) {
                        const restaurantData = restaurantDoc.data();
                        const fetchedRestaurant = {
                            id: restaurantDoc.id,
                            ...restaurantData,
                        };

                        dispatch(fetchSingleRestaurantSuccess([fetchedRestaurant]));
                        setRestaurantData(fetchedRestaurant); // Store the fetched restaurant data
                    } else {
                        dispatch(fetchSingleRestaurantFailure('Restaurant not found'));
                    }
                } catch (error) {
                    dispatch(fetchSingleRestaurantFailure(error.message));
                }
            };
            fetchSingleRestaurant(restaurantid); // Call with restaurantid
        }
    }, [dispatch, restaurantid]); // Use restaurantid as a dependency

    const calculateDeliveryFee = (totalPrice) => {
        const initialFee = 15;
        let deliveryFee = initialFee;
      
        // Handle totalPrice less than or equal to 200
        if (totalPrice <= 200) {
          deliveryFee = 15; // Fixed delivery fee for total price <= 200
        } 
        // Handle totalPrice between 201 and 300 (inclusive)
        else if (totalPrice > 200 && totalPrice <= 300) {
          deliveryFee += 5; // Add 5 to initial fee for total price between 201 and 300
        } 
        // Handle totalPrice between 301 and 500 (inclusive)
        else if (totalPrice > 300 && totalPrice <= 500) {
          deliveryFee += 10; // Add 10 to initial fee for total price between 301 and 500
        } 
        // Handle totalPrice greater than 500
        else {
          deliveryFee += 15; // Add 15 to initial fee for total price above 500
        }
      
        return deliveryFee;
      };
      
      
      
    const sendOrderToTelegram = async (order,documentId) => {
        generateUUID(); // Call the generateUUID method
        // const sendMessageto=[order.telegramId,order.host_name,order.user_id]
        const orderItemsList = order.orders.map((item, index) => {
            return `${index + 1}  - ${item.productName.padEnd(10)}     $${item.price.toFixed(2).padEnd(6)}    ${item.quantity}`;
        }).join('\n');

        // Retrieve user data from local storage
        const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
        const name = userProfile.name || 'N/A'; // Fallback to 'N/A' if name is not available
        const phone = userProfile.phone || 'N/A'; // Fallback to 'N/A' if phone is not available
        const blockNumber = userProfile.dormNumber || 'N/A'; // Fallback to 'N/A' if dormNumber is not available

        // Ensure fees and total amount are numbers
         // Ensure fees and total amount are numbers
         
        const serviceFee = Number(order.service_fee) || 0;
        const deliveryFee = Number(calculateDeliveryFee(order.totalAmount))|| 0;
        const totalAmount = Number(order.totalAmount+serviceFee+deliveryFee) || 0;

        let message = `*From Restaurant: \n${order.from_hotel}*
    *Order Products List:*
    ID   Name         Price       Quantity
    ----------------------------------------
    ${orderItemsList}
    
    *Service Fees:* ${serviceFee.toFixed(2)} ETB
    *Delivery Fees:* ${deliveryFee.toFixed(2)} ETB
    *Total Price:* ${totalAmount.toFixed(2)} ETB
    
    
    *Customer Details:*
    - Name: ${name}
    - Phone: ${phone}
    - Block Number: ${blockNumber}
   
    
    *Distance (Kilometers):*
    -Location : ${order.h_address}
    - ${order.distance ? order.distance.toFixed(3) : 'N/A'} KM
        `.trim(); 


        sendMessagesSequentially(order, message,userId,documentId);
    }

   
  // Sequentially send messages to different users
  async function sendMessagesSequentially(order, message, userId,documentId) {
    // Tokens for different bots
    const tokenYeencafe = '5685380140:AAHsgq8SUaQ10yHhxU_b-CYBMG01farQFXw'; // Yeencafe
    const tokenRestaurants = '6054843791:AAHeMZsDT2wYTfMUdPr_rlsYa68fqgdFcsQ'; // Yene Restaurants

    // Define custom messages
    const messageForCustomer = `${message}\nYour order will be delivered in 30 minutes.`;
    const messageForRestaurantAdmin = `For Ambassador\n *New order is coming * \n${message}`;
    const messageForCEO = `FOR CEO\n*New order Incoming*\n${message}`;
    const messageForHotelsRestaurants = `Message for restaurants\n *New order incoming*\n${message}`;

    // Define inline keyboards
    const inlineKeyboardCustomer = { inline_keyboard: [] };
    const inlineKeyboardRestaurantAdmin = {
      inline_keyboard: [
        [{ text: "Restaurant admin", callback_data: `accept_order_${documentId}` }]
      ]
    };
    const inlineKeyboardCEO = {
      inline_keyboard: [
        [{ text: "Accept CEO", callback_data: `accept_order_${documentId}` }, { text: "Decline CEO", callback_data: `decline_order_${documentId}` }]
      ]
    };
    const inlineKeyboardHotelsRestaurants = {
      inline_keyboard: [
        [{ text: "Accept for Hotel", callback_data: `accept_order_${documentId}` }, { text: "Decline for Hotel", callback_data: `decline_order_${documentId}` }]
      ]
    };

    // Sequentially send messages
    try {
      // Send message to customer
      await sendMessageToChat(userId, messageForCustomer, inlineKeyboardCustomer, tokenYeencafe);

      // Send message to restaurant admin
      await sendMessageToChat(order.host_name, messageForRestaurantAdmin, inlineKeyboardRestaurantAdmin, tokenRestaurants);

      // Send message to CEO
      await sendMessageToChat(order.admin, messageForCEO, inlineKeyboardCEO, tokenRestaurants);

      // Send message to hotels/restaurants
      await sendMessageToChat(order.h_telegram, messageForHotelsRestaurants, inlineKeyboardHotelsRestaurants, tokenRestaurants);

    
    } catch (error) {
      
    }
  }

  // Function to send a message to a specific chat ID
  async function sendMessageToChat(chatId, customMessage, replyMarkup, token) {
    try {
      await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
        chat_id: chatId,
        text: customMessage,
        parse_mode: 'Markdown',
        reply_markup: replyMarkup
      });
      
    } catch (error) {
      
    }
  }



    const handlePlaceOrder = async () => {
        setIsLoading(true); // Show spinner when order starts processing

        const uuid = generateUUID(); // You can now capture the returned UUID
        const order = {
            // Assuming you have a userId
            'h_id': cartItems[0].h_id,
            'telegramId': Number(userId), //  this is used to send the hotel
            'h_telegram': restaurantData.telegramId,  // this is  restaurants id
            'admin': Number(761513957), // Send Message to The CEO
            'host_name': Number(restaurantData.host_name), // this is university admin
            'user_id': Number(userId),                    // this is user  orderd id
            'from_hotel': restaurantData.h_name,
            'with_delivery': "",
            'h_address': restaurantData.h_address,
            'order_status': "Pending",
            'order_code': uuid,
            'service_fee': "10",
            "message":"",
            'delivery_fee': "10",
            'orders': cartItems.map(item => ({
                productId: item.id,
                productName: item.p_name,
                quantity: item.quantity,
                price: item.p_price,
            })),
            totalAmount: cartItems.reduce((sum, item) => sum + item.p_price * item.quantity, 0),
            'order_time': new Date().toISOString(),
        };


        try {
            
            // await addDoc(collection(db, 'Telegram-OrderdList'), order);

            // Add order to Firestore and get the document reference
            const docRef = await addDoc(collection(db, 'Telegram-OrderdList'), order);

            // Get the document ID
            const documentId = docRef.id;
            await sendOrderToTelegram(order,documentId); // Call function to send message to Telegram
            dispatch(clearCart());
            setShowCart(false);
            navigate(`/tabbed`);

        } catch (error) {
            alert('Failed to place order: ' + error.message);
            setIsLoading(false); // Hide spinner after order is processed
        }


    };

    const handleRemoveFromCart = (productId) => {
        dispatch(removeFromCart(productId));
    };
    return (
        <div className="app-container-nearby">
            <div className="search-cart-container-nearby">
                <input
                    type="text"
                    className="search-input-nearby"
                    placeholder="Search for restaurants and products."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {cartItems.length > 0 && (
                    <button className="cart-button-nearby" onClick={() => setShowCart(!showCart)}>
                        {showCart ? 'Hide Cart' : `Cart (${cartItems.length})`}
                    </button>
                )}
            </div>

            {loading && <div className="loading-message">Loading...</div>}
            {error && <div className="error-message">Error: {error}</div>}

            <ul className="product-list-nearby">
                {filteredProducts.map((product) => {
                    const cartItem = cartItems.find(item => item.id === product.id);
                    const quantity = cartItem ? cartItem.quantity : 0;

                    return (
                        <li className="list-item-nearby" key={product.id}>
                            <div className="list-item-content">
                                <div className="list-item-avatar">
                                    <img
                                        src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStOB26e6FqhS8YWtkvN0L3cbFpupGF5VN8XA&s'}
                                        alt={product.p_name}
                                        className="avatar"
                                    />
                                </div>
                                <div className="list-item-text">
                                    <h2 className="product-name">{product.p_name} {product.p_price} ETB</h2>
                                    <p className="product-price">From : {product.fromHotel}  </p>

                                </div>
                                <div className="quantity-controls">
                                    {quantity > 0 ? (
                                        <>
                                            <button className="control-button" onClick={() => handleDecrement(product.id)}>-</button>
                                            <span className="quantity">{quantity}</span>
                                            <button className="control-button" onClick={() => handleIncrement(product.id)}>+</button>
                                        </>
                                    ) : (
                                        <button className="add-to-cart-button" onClick={() => handleAddToCart(product)}>Add to Cart</button>
                                    )}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>

            {showCart && (
                <div className={`cart-sidebar ${showCart ? 'slide-in' : 'slide-out'}`}>
                    <button className="close-button-nearby" onClick={closeCart}>X</button>
                    <h2>Cart Items</h2>
                    {cartItems.length === 0 ? (
                        <p>No items in the cart.</p>
                    ) : (
                        <>
                            {cartItems.map(item => (
                                <div key={item.id} className="cart-item">
                                    <span>{item.p_name} x {item.quantity}</span>
                                    <span>{(item.p_price * item.quantity).toFixed(2)}</span>
                                    <button className="remove-button" onClick={() => handleRemoveFromCart(item.id)}>Remove</button>
                                </div>
                            ))}
                            {/* <button className="order-button" onClick={handlePlaceOrder}>Place Order</button> */}
                            {!isLoading ? (
                            <button className="order-button" onClick={handlePlaceOrder}>
                                Place Order
                            </button>
                            ) : (
                            <div className="spinner"></div> // Show spinner when loading
                            )}
                        </>
                    )}

                    {/* Render restaurant data when available */}
                    {restaurantData && (
                        <div className="restaurant-info">
                            <h2>Restaurant Information</h2>
                            <p>Name: {restaurantData.h_name}</p>
                            <p>Address: {restaurantData.h_address}</p>
                            <p>Phone: {restaurantData.h_phone}</p>

                        </div>
                    )}
                </div>
            )}



        </div>
    );
}

export default NearbyProductsPage;
