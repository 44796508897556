import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import '../style/ProfileForm.css';
import { db } from '../firebaseConfig';
import { doc, setDoc, getDoc, GeoPoint } from 'firebase/firestore';
const tele = window.Telegram.WebApp;


const ProfileForm = ({ onProfileSave }) => {

  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    university: '',
    dormNumber: '',
    lat: '',
    lon: '',
    userId: '', // Initially empty, will be set from Telegram WebApp
  });

  useEffect(() => {
    tele.ready();
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [locationAccess, setLocationAccess] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  // Predefined university coordinates
  const universityCoordinates = {
    'Mekelle University': { lat: 13.49, lon: 39.47 },
    'Hawassa University': { lat: 7.06, lon: 38.48 },
    'Gondar University': { lat: 12.6, lon: 37.46 },
    'Arba Minch University': { lat: 6.03, lon: 37.55 },
    'Adama Science and Technology University': { lat: 8.55, lon: 39.27 },
    'Debre Berhan University': { lat: 9.67, lon: 39.52 },
    'Jimma University': { lat: 7.67, lon: 36.83 },
    'Addis Ababa University': { lat: 9.03, lon: 38.74 },
    'Bahir Dar University': { lat: 11.60, lon: 37.39 },
    'Dilla University': { lat: 6.42, lon: 38.31 },
    'Dire Dawa University': { lat: 9.60, lon: 41.85 },
    'Debre Markos University': { lat: 10.37, lon: 37.73 },
    'Kombolcha University': { lat: 11.08, lon: 39.78 },
    'Woldia University': { lat: 11.52, lon: 39.60 },
    'Wollo University': { lat: 11.75, lon: 39.60 },
    'Haramaya University': { lat: 9.30, lon: 42.05 },
    'Gambela University': { lat: 8.25, lon: 34.58 },
    'Mettu University': { lat: 8.38, lon: 34.29 },
    'Shashemene University': { lat: 7.20, lon: 38.60 },
    'Bule Hora University': { lat: 7.08, lon: 39.61 },
  };

  useEffect(() => {
    tele.ready();
    // Function to get user ID
    const getUserId = () => {
      tele.ready();
      // const tele = window.Telegram?.WebApp;
      // Try to retrieve the user ID from Telegram WebApp (for official apps)
      const userIdFromTelegram = tele?.initDataUnsafe?.user?.id;

      // If we can't retrieve user ID from Telegram WebApp, fallback to other methods
      if (userIdFromTelegram) {
        setFormData((prevData) => ({
          ...prevData,
          userId: userIdFromTelegram,
        }));
      } else {
        // Check if userId is available in URL parameters (unofficial apps could pass it)
        const userIdFromUrl = new URLSearchParams(window.location.search).get('userId');

        if (userIdFromUrl) {
          setFormData((prevData) => ({
            ...prevData,
            userId: userIdFromUrl,
          }));
        } else {
          // Fallback to a default value if both methods fail
          // setFormData((prevData) => ({
          //   ...prevData,
          //   userId: 6015838529, // Your default fallback ID
          // }));

          setErrorMessage('Unable to retrieve user ID from Telegram or URL.');
        }
      }
    };

    getUserId();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Update latitude and longitude based on selected university
    if (name === 'university') {
      const coords = universityCoordinates[value] || { lat: '', lon: '' };
      setFormData((prevData) => ({
        ...prevData,
        lat: coords.lat,
        lon: coords.lon,
      }));
    }
  };
 
  const handleLocationRequest = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prevData) => ({
            ...prevData,
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          }));
          setLocationAccess(true);
        },
        () => {
          setLocationAccess(false);
          alert('Unable to retrieve your location. Please turn on location access.');
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate phone number
    const phonePattern = /^09\d{8}$/; // Regex for 10-digit phone starting with 09
    if (!phonePattern.test(formData.phone)) {
      setErrorMessage('Phone number must be 10 digits and start with "09".');
      return;
    }

    // Validate required fields
    if (!formData.fullName || !formData.university || !formData.dormNumber || !formData.lat || !formData.lon) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    const timestamp = new Date().toISOString();
    const location = new GeoPoint(parseFloat(formData.lat), parseFloat(formData.lon));

    const userData = {
      location: location,
      timestamp: timestamp,
      name: formData.fullName,
      phone: formData.phone,
      role: 'user',
      university: formData.university,
      dormNumber: formData.dormNumber,
      lat:location.latitude,
      lon:location.longitude,
      userId: formData.userId, // Include userId here
    };

    try {
      // Check if user ID exists in Firebase
      const userDocRef = doc(db, "TelegramUsers", `${formData.userId}`);
      
      // Save the profile data to Firestore
      await setDoc(userDocRef, userData);

      // Save profile data to local storage
      localStorage.setItem('userProfile', JSON.stringify(userData));

      onProfileSave(); // Notify parent component

  
      navigate('/'); // Assuming "/" is your bottom navigation tab pages route
      window.location.reload(); // This will refresh the page
    } catch (e) {
      
      setErrorMessage('An error occurred while saving your profile. Please try again.');
    }
  };

  return (
    <div className="form-container">
      <form className="profile-form" onSubmit={handleSubmit}>
        <h2> Yene Delivery Register</h2>

        <label htmlFor="fullName">Full Name</label>
        <input
          type="text"
          id="fullName"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
          placeholder="Enter full name"
        />

        <label htmlFor="phone">Phone</label>
        <input
          type="number"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          placeholder="09XXXXXXXX"
        />

        <label htmlFor="university">University</label>
        <select
          id="university"
          name="university"
          value={formData.university}
          onChange={handleChange}
          required
        >
          <option value="">Select University</option>
          {Object.keys(universityCoordinates).map((uni, index) => (
            <option key={index} value={uni}>
              {uni}
            </option>
          ))}
        </select>

        <label htmlFor="dormNumber">Dorm Number</label>
        <input
          type="text"
          id="dormNumber"
          name="dormNumber"
          value={formData.dormNumber}
          onChange={handleChange}
          required
          placeholder="Enter Dorm Block or Home number"
        />

        <button type="button" onClick={handleLocationRequest}>
          Turn on Location
        </button>

        {locationAccess === false && (
          <p className="error-message">
            Unable to access your location. Using coordinates from the selected university.
          </p>
        )}

        <p>
          Latitude: {formData.lat || 'N/A'}, Longitude: {formData.lon || 'N/A'}
        </p>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {formData.lat && formData.lon && (
          <button type="submit">Submit</button>
        )}
      </form>
      <div className="footer-message">
        <p>All rights reserved &copy; Yene Food Delivery.</p>
        <p>We provide quick and reliable food delivery services to your doorstep. Enjoy your meals with just a click!</p>
      </div>
    </div>
  );
};

export default ProfileForm;
