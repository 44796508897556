import React, { useState } from 'react';
import OrdersPage from '../Pages/ShowMyOrders';
import LocationHelperPage from '../Pages/HelpByLocation'; // Import your helper page
import Profile from '../Pages/Profile';
import '../style/TabbedPage.css'; // Assuming you'll create this CSS file for styles

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={{ display: value === index ? 'block' : 'none' }} // Show/Hide panel
        >
            {children}
        </div>
    );
};

const TabbedPage = ({ lat, lon }) => {
   
    const [value, setValue] = useState(0);

    const handleChange = (index) => {
        setValue(index);
    };

    return (
        <div className="tabbed-page-container">
            <div className="tabs">
                <ul className="tab-list">
                    <li
                        className={`tab-link ${value === 0 ? 'active' : ''}`}
                        onClick={() => handleChange(0)}
                        id="tab-0" // Unique ID for the tab
                    >
                        My Orders
                    </li>
                    <li
                        className={`tab-link ${value === 1 ? 'active' : ''}`}
                        onClick={() => handleChange(1)}
                        id="tab-1" // Unique ID for the tab
                    >
                        Helper
                    </li>
                    <li
                        className={`tab-link ${value === 2 ? 'active' : ''}`}
                        onClick={() => handleChange(2)}
                        id="tab-2" // Unique ID for the tab
                    >
                        Profile
                    </li>
                </ul>
            </div>
            <br />

            <TabPanel value={value} index={0}>
                <OrdersPage />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <LocationHelperPage lat={lat} lon={lon} />
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Profile />
            </TabPanel>
        </div>
    );
};

export default TabbedPage;
